import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Flex,
  Text,
  Center,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
// import "./FAQ.css";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";

const FAQ = () => {
  const isMobile = window.innerWidth <= 767;
  const itemWidth = !isMobile ? "60vw" : "80vw";
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        background: "#eddfd2",
      }}
    >
      <Header />

      <Center>
        <div
          style={{
            marginTop: "5vh",
            fontSize: isMobile ? "1.5rem" : "2.0rem",
            width: isMobile ? "60vw" : "",
            color: "black.400",
            fontFamily: "Poppins Bold",
            textAlign: "center", // Add this line to center the text horizontally
          }}
        >
          FREQUENTLY ASKED QUESTIONS (FAQ)
        </div>
      </Center>
      <Center>
        <Flex
          style={{
            marginTop: "7.5vh",
            marginBottom: "7.5vh",
            background: "#eddfd2",
          }}
        >
          <div style={{ width: isMobile ? "80vw" : "60vw" }}>
            <Accordion allowMultiple width="100%" maxW="xlg" rounded="lg">
              <AccordionItem style={{ width: itemWidth }}>
                <AccordionButton
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={4}
                >
                  <Text fontSize="md">What is a mobile notary service?</Text>
                  <ChevronDownIcon fontSize="24px" />
                </AccordionButton>
                <AccordionPanel textAlign="left" pb={4}>
                  <Text color="gray.600">
                    A mobile notary service provides notary public services at a
                    location convenient for you, such as your home, office, or a
                    coffee shop.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem style={{ width: itemWidth }}>
                <AccordionButton
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={4}
                >
                  <Text fontSize="md">
                    How does your mobile notary service work?
                  </Text>
                  <ChevronDownIcon fontSize="24px" />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text color="gray.600">
                    Simply schedule an appointment with us. We’ll come to your
                    preferred location, verify your identity, and notarize your
                    documents.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem style={{ width: itemWidth }}>
                <AccordionButton
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={4}
                >
                  <Text fontSize="md">
                    What should I bring to the appointment?
                  </Text>
                  <ChevronDownIcon fontSize="24px" />
                </AccordionButton>
                <AccordionPanel textAlign="left" pb={4}>
                  <Text color="gray.600">
                    Please bring a valid government-issued ID, the document(s)
                    to be notarized, and any other required parties. <br />{" "}
                    <br />
                    What if I don’t have an ID? If the signer of a document does
                    not have a valid ID (not expired), two credible identifying
                    witnesses (with valid IDs) must be present at the signing of
                    all documents. One credible witness is acceptable when the
                    Notary Public and the signer both know the witness through
                    personal knowledge.
                    <br /> <br />
                    This witness must meet the following four requirements: 1.
                    The individual making the acknowledgement is the same person
                    identified in the document.
                    <br /> <br />
                    2. The individual making the acknowledgement does not
                    possess any valid identification.
                    <br /> <br />
                    3. That it is the reasonable belief of the witness that the
                    circumstances of the person making the acknowledgment are
                    such that it would be very difficult or impossible for that
                    person to obtain another form of identification.
                    <br /> <br />
                    4. The witness does not have any financial interest in the
                    document being acknowledged and is not named in the
                    document. Two credible witnesses are used when the
                    identities of the witnesses are proven to the Notary based
                    on satisfactory evidence. The two witnesses must be known to
                    the signer through personal knowledge and must meet the four
                    requirements mentioned above.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem style={{ width: itemWidth }}>
                <AccordionButton
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={4}
                >
                  <Text fontSize="md">
                    How long does the notarization process take?
                  </Text>
                  <ChevronDownIcon fontSize="24px" />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Text color="gray.600">
                    The time varies based on the complexity of the document.
                    Typically, it takes around 15-30 minutes.
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </Flex>
      </Center>
      <div style={{ flex: 1 }}></div>
      <Footer />
    </div>
  );
};

export default FAQ;
