import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../Header/Header";
import aboutMePic from "../../assets/images/aboutMePic.jpeg";
import "./AboutMe.css";

export const AboutMe = (props) => {
  const [showSignUpIn, setShowSignUpIn] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    console.log(localStorage.getItem("id"));
  }, []);

  return (
    <div>
      <Header />
      <div class="">
        <div
          style={{
            backgroundColor: "#eddfd2",
            minHeight: "100vh",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center horizontally
            // justifyContent: "center", // Center vertically
          }}
        >
          <br />
          <div style={{ width: "90%", maxWidth: "800px" }}>
            <div className="about-me-container">
              <div className="column">
                <div
                  className="greeting"
                  style={{ fontSize: "24px", fontFamily: "Tilt Neon" }}
                >
                  <span>Hi there! I'm Marissa👋</span>
                  <br />
                  <br />
                </div>
                <p style={{ fontFamily: "Tilt Neon" }}>
                  Welcome to Notary Expresso, mobile Notary Services! With
                  nearly a decade of experience in customer service and a
                  background in insurance, I’m here to make your notarization
                  needs a breeze. My mission is simple: to be the helping hand
                  you need when it matters most. Whether it’s for personal
                  documents, legal forms, or anything in between, I’m dedicated
                  to providing a convenient, on-the-go notary service that
                  caters to your schedule. I’m at your service, ready to bring
                  professionalism and a friendly smile to your doorstep. Contact
                  me today for reliable, efficient, and customer-centric notary
                  services that you can count on.
                </p>
              </div>
              <div className="column">
                <div className="profile-picture">
                  <img
                    src={aboutMePic}
                    alt="Profile"
                    style={{ width: "100%", height: "auto" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
