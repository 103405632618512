import React, { useState, useEffect } from "react";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import "./Header.css";
import updatedTest from "../../assets/images/updatedTest.svg";
import Services from "../Services/Services";

const Header = () => {
  const [showSignUpIn, setShowSignUpIn] = useState(false);
  const navigate = useNavigate();

  const navHome = () => {
    navigate("/");
  };

  const navFAQ = () => {
    navigate("/faq");
  };

  const navAboutMe = () => {
    navigate("/aboutMe");
  };

  const navAppointment = () => {
    navigate("/appointment");
  };

  const navServices = () => {
    navigate("/services");
  };

  useEffect(() => {
    console.log(localStorage.getItem("id"));
  }, []);

  const isMobile = window.innerWidth <= 767;

  return (
    <div className="headerContainer">
      <div className="logoContainer">
        <img
          onClick={navHome}
          src={updatedTest}
          alt="logo"
          style={{ width: 350 }}
        />
      </div>
      <div className="menuContainer">
        <div onClick={navHome} className="logoFont">
          Home
        </div>
        <div onClick={navFAQ} className="logoFont">
          FAQ
        </div>
        <div onClick={navServices} className="logoFont">
          Services
        </div>
        <div onClick={navAboutMe} className="logoFont">
          About Marissa
        </div>
        <div
          className="logoFont"
          data-tally-open="n972op"
          data-tally-layout="modal"
          data-tally-width="375"
          data-tally-emoji-text="👋"
          data-tally-emoji-animation="wave"
        >
          Contact Us
        </div>
      </div>
      {isMobile ? null : (
        <Button
          style={{ marginLeft: 30 }}
          className="bookButton"
          onClick={navAppointment}
          colorScheme="teal"
          size="lg"
        >
          Book a meeting now
        </Button>
      )}
    </div>
  );
};

export default Header;
