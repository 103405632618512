import Header from "../Header/Header";

const Appointment = () => {
  console.log("Home");
  return (
    <div>
      <Header />
      <div>
        <iframe
          title="Calendly Embed"
          src="
https://calendly.com/notaryexpresso/notary-signing"
          style={{ border: 0, width: "100%", height: "80vh" }}
          seamless
        />
      </div>
    </div>
  );
};
export default Appointment;
