import React from "react";
import { Button, Text } from "@chakra-ui/react";
import Header from "../Header/Header";
import TestamonialContent from "../Testamonial/Testamonial";
import FooterTest from "../Footer/Footer";
import FAQ from "../FAQ";
import lawAnimation from "../../assets/lottie/law.json";
import AboutMe from "../AboutMe/AboutMe";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import "./Home.css";

const Home = () => {
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lawAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const navAppointment = (eventId) => {
    console.log("hi");
    navigate("/appointment");
  };

  const availableHours = [
    "Monday to Friday: 6pm to 9pm",
    "Saturday and Sunday: 8am to 9pm",
  ];

  return (
    <div className="home-container">
      <Header />
      <div className="animation-container">
        <Lottie
          options={defaultOptions}
          style={{ width: "65%", height: "65%" }}
        />
        <div className="text-container">
          <div className="title">
            The best mobile notary and signing services you'll ever need
          </div>
          <Button
            style={{ marginTop: 25, marginBottom: 25 }}
            onClick={navAppointment}
            colorScheme="teal"
            size="lg"
          >
            Book a meeting now
          </Button>
        </div>
      </div>

      <div className="available-hours">
        <Text as="div" className="landingHeaderFont">
          <br />
          Available Hours
          <br />
        </Text>
        <ul>
          {availableHours.map((hour, index) => (
            <div className="landingHeaderFont" key={index}>
              {hour}
            </div>
          ))}
        </ul>
      </div>
      <TestamonialContent />

      <div style={{ marginTop: "10vh", marginBottom: "5vh" }}>
        <FooterTest />
      </div>
    </div>
  );
};

export default Home;
