import React from "react";
import { Routes, Route, Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import { useEffect } from "react";

import Home from "./components/Home/Home";
import Appointment from "./components/Appointment/Appointment";
import AboutMe from "./components/AboutMe/AboutMe";
import Services from "./components/Services/Services";
import FAQ from "./components/FAQ";

import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

// Specify all properties: name, family, style
icon({ name: "user", family: "classic", style: "solid" });
icon({ name: "user", family: "classic", style: "regular" });
icon({ name: "twitter", family: "classic", style: "brands" });

// 'classic' is the default family, you can leave that off
icon({ name: "user", style: "solid" });
icon({ name: "user", style: "regular" });
icon({ name: "twitter", style: "brands" });

// 'solid' is the default style, you can leave that off
icon({ name: "user" });

const CustomRouter = ({ basename, children, history }) => {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      basename={basename}
      children={children}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

function App() {
  const history = createBrowserHistory();

  useEffect(() => {}, []);

  return (
    <CustomRouter history={history}>
      <Routes>
        <Route exact path="/" element={<Home history={history} />} />
        <Route
          exact
          path="/appointment"
          element={<Appointment history={history} />}
        />
        <Route exact path="/aboutMe" element={<AboutMe history={history} />} />
        <Route
          exact
          path="/services"
          element={<Services history={history} />}
        />
        {/* add faq */}
        <Route exact path="/faq" element={<FAQ history={history} />} />
      </Routes>
    </CustomRouter>
  );
}

export default App;
