import React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
  Fade,
} from "@chakra-ui/react";

const Testimonial = ({ children }) => {
  return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }) => {
  return (
    <Stack
      bg={"#eddfd2"}
      boxShadow={"lg"}
      p={8}
      rounded={"xl"}
      align={"center"}
      pos={"relative"}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: "solid transparent",
        borderLeftWidth: 16,
        borderRight: "solid transparent",
        borderRightWidth: 16,
        borderTop: "solid",
        borderTopWidth: 16,
        borderTopColor: useColorModeValue("white", "gray.800"),
        pos: "absolute",
        bottom: "-16px",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }) => {
  return (
    <Heading style={{ fontFamily: "Tilt Neon" }} as={"h3"} fontSize={"xl"}>
      {children}
    </Heading>
  );
};

const TestimonialText = ({ children }) => {
  return (
    <Text
      style={{ fontFamily: "Tilt Neon" }}
      textAlign={"center"}
      color={useColorModeValue("gray.600", "gray.400")}
      fontSize={"sm"}
    >
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({ src, name, title }) => {
  return (
    <Flex align={"center"} mt={8} direction={"column"}>
      <Avatar src={src} mb={2} />
      <Stack spacing={-1} align={"center"}>
        <Text fontWeight={600}>{name}</Text>
        <Text fontSize={"sm"} color={useColorModeValue("gray.600", "gray.400")}>
          {title}
        </Text>
      </Stack>
    </Flex>
  );
};

function TestamonialContent() {
  return (
    <Box bg={useColorModeValue("#EDDFD2", "gray.700")}>
      <Container maxW={"7xl"} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={"center"}>
          <div className="landingHeaderFont">Our Clients Speak</div>
          <Text style={{ fontFamily: "Tilt Neon" }}>
            We have been working with clients all across the United States
          </Text>
        </Stack>

        {/* I recently had the pleasure of working with a notary
                professional to finalize the sale of my property. Their
                attention to detail and professionalism made the entire process
                smooth and stress-free. */}
        <Fade in={true}>
          <Stack
            bg={"#eddfd2"}
            py={2}
            px={8}
            spacing={{ base: 8, md: 4 }}
            align={"center"}
            direction={"column"}
          >
            <Text
              fontSize={{ base: "xl", md: "2xl" }}
              textAlign={"center"}
              maxW={"3xl"}
            >
              I recently had the pleasure of working with Marissa to finalize
              the sale of my property. Her attention to detail and
              professionalism made the entire process smooth and stress-free.
            </Text>
            <Box textAlign={"center"}>
              <Avatar
                src={
                  "https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80"
                }
                mb={2}
              />

              <Text fontWeight={600}>Jenny Wilson</Text>
              <Text
                fontSize={"sm"}
                color={useColorModeValue("gray.400", "gray.400")}
              >
                Tucson, Arizoa
              </Text>
            </Box>
          </Stack>
        </Fade>
        <Stack
          bg={"#eddfd2"}
          py={2}
          px={8}
          spacing={{ base: 8, md: 4 }}
          align={"center"}
          direction={"column"}
        >
          <Text
            fontSize={{ base: "xl", md: "2xl" }}
            textAlign={"center"}
            maxW={"3xl"}
          >
            I had the best experience with Marissa when I was purchasing my
            first home. She patiently explained all the documents and ensured I
            understood every detail, making me feel confident and secure in such
            a significant investment.
          </Text>
          <Box textAlign={"center"}>
            <Avatar
              src={
                "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=5070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              }
              mb={2}
            />

            <Text fontWeight={600}>Jenny Wilson</Text>
            <Text
              fontSize={"sm"}
              color={useColorModeValue("gray.400", "gray.400")}
            >
              Vice President
            </Text>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}

export default TestamonialContent;
