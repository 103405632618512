// Services.js

import React, { useState, useEffect } from "react";
import { Button, Divider } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import "./Services.css";
import Header from "../Header/Header";

export const Services = (props) => {
  const [showSignUpIn, setShowSignUpIn] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    console.log(localStorage.getItem("id"));
  }, []);

  return (
    <div>
      <Header />
      <div className="services-container">
        <div>
          <h1 className="page-title">Our Services</h1>
        </div>
        <div className="services-list">
          <div className="service-category">
            <h2>Pricing structure</h2>
            <ul>
              <li>$12/ per signature</li>
              <li>$1/ per printer page</li>
              <li>$15/ excessive wait fee</li>
              <li>$15 travel fee for the first 10 miles</li>
              <li>$30 travel fee for the first 11-20 miles</li>
              <li>$30 travel fee for the first 20-30 miles</li>
              <li>$60 travel fee for the first 31-50 miles</li>
              <li>$100 travel fee for the first 50 miles+</li>
              <li>$20 for express/ emergency notarization</li>
            </ul>
          </div>

          <div className="service-category">
            <h2>1. General Notarization Services</h2>
            <ul>
              <li>
                Mobile Notary Services: We come to you for your convenience.
              </li>
              <li>
                Acknowledgments: Authenticate signatures on legal documents.
              </li>
              <li>Jurats: Administer oaths and affirmations.</li>
              <li>
                Copy Certification: Certify true copies of original documents.
              </li>
            </ul>
          </div>
          <div className="service-category">
            <h2>2. Legal and Business Documents</h2>
            <ul>
              <li>Contracts: Notarize business and legal contracts.</li>
              <li>Power of Attorney: Assist in POA document notarization.</li>
              <li>Trusts and Wills: Notarize estate planning documents.</li>
            </ul>
          </div>
          <div className="service-category">
            <h2>3. Real Estate Transactions</h2>
            <ul>
              <li>Deeds: Ensure the legality of property transfers.</li>
              <li>
                Title Transfers: Validate vehicle and boat title transfers.
              </li>
              <li>Bill of Sale: Notarize bills of sale for various assets.</li>
            </ul>
          </div>
          <div className="service-category">
            <h2>4. Healthcare Directives</h2>
            <ul>
              <li>
                Advance Healthcare Directives: Assist with medical planning.
              </li>
              <li>Living Wills: Notarize end-of-life care directives.</li>
            </ul>
          </div>

          <div className="service-category">
            <h2>5. Customized Notary Solutions</h2>
            <ul>
              <li>Tailored services to meet unique notary needs.</li>
            </ul>
          </div>
          <div className="service-category">
            <h2>6. After-Hours and Emergency Services</h2>
            <ul>
              <li>Availability beyond standard business hours.</li>
              <li>Emergency notarizations when you need them.</li>
            </ul>
          </div>
          <div className="service-category">
            <h2>7. Mobile Notary Coverage</h2>
            <ul>
              <li>
                Serving the following areas:
                <br />
                <ul>
                  <div>
                    San Jose, Milpitas, Fremont, Redwood City, Palo Alto
                  </div>
                  <div>Los Gatos, Campbell, Cupertino, Morgan Hill, Gilroy</div>
                  <div>
                    Aptos, Santa Cruz, Monterey, Marina, Carmel, Salinas
                  </div>
                  <div>Seaside, Pacific Grove, Mountain View, Sunnyvale</div>
                  <div>Santa Clara, Watsonville, Ben Lomond, Scotts Valley</div>
                  <div>and surrounding areas.</div>
                </ul>
              </li>

              <em>
                * Urgent /emergency services are available with an additional
                fee *
              </em>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
